/* Global error handling */
document.addEventListener("htmx:responseError", (e) => {
    let errorDiv = document.querySelector("#error-msg");
    console.error(e.detail.error);
    errorDiv.innerHTML = e.detail.xhr.response;
    errorDiv.scrollIntoView();
});

/* Global no-content success handling */
document.body.addEventListener("successMessage", function(evt){
    const successDiv = document.querySelector("#success-msg");
    const divLeftOffset = successDiv.offsetLeft;
    const alertDiv = `<div class="uk-alert-success uk-animation-slide-top" style="position: absolute; left: ${divLeftOffset}px; right: 0; margin: 0 auto; text-align: center;" uk-alert><a href class="uk-alert-close" uk-close></a><div>${evt.detail.value}</div></div>`
    successDiv.insertAdjacentHTML('beforeend', alertDiv);
    setTimeout(() => {
        var alert = document.querySelector("div[uk-alert]");
        alert.classList.remove("uk-animation-slide-top");
    }, 300)
    setTimeout(() => {
        var alert = document.querySelector("div[uk-alert]");
        alert.classList.add("uk-animation-slide-top-medium", "uk-animation-reverse");
        setTimeout(() => {alert.remove()}, 500)
    }, 3000);
    htmx.process(successDiv);
});

document.body.addEventListener("errorMessage", function(evt){
    alert(evt.detail.value);
});

/* Global spinner/loader logic */
document.addEventListener("htmx:beforeRequest", () => {
    const spinnerDiv = document.querySelector("#spinner");
    spinnerDiv.classList.remove("uk-hidden");
})
document.addEventListener("htmx:afterRequest", () => {
    const spinnerDiv = document.querySelector("#spinner");
    spinnerDiv.classList.add("uk-hidden");
})
